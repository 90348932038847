@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crete+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 50%;
  padding-bottom: 0;
  z-index: 2;
}
.nav-link {
  color: #e0e0e0;
  font-size: 0.8rem;
  font-family: "Noto Sans KR", sans-serif;
  padding: 0;
  margin: 0;
  font-weight: 800;
}
.fas:hover {
  color: white;
}
.logo{
  font-family: 'Crete Round', serif;
  color: #e0e0e0;
  font-size: 1.8rem;
  border-radius: 50%;
  padding: 0 8px;
  border: 1px solid white;
  margin: 0;

 
  -webkit-animation-name: example;

 
          animation-name: example;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}
.logo:hover{
  color: black;
  background: white;
}

@media only screen and (max-width: 700px) {
  #navbar {
    padding-right: 0;
  }
}



@-webkit-keyframes example {
  from {background-color: white;color: black;}
  to {background-color: black;color: white;}
}



@keyframes example {
  from {background-color: white;color: black;}
  to {background-color: black;color: white;}
}

.carousel img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 100vh;
  object-fit:cover;
  width:100vw;
}
.legend {
  margin-bottom: 80px;
  font-family: 'Raleway', sans-serif;

}

@media screen and (max-width: 450px) {

  .legend {
    margin-bottom: 10px;
  
  }
}

#about{
    height: 100vh;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#text-about{
    width: 60%;
    text-align: left;
}
#container{
    width: 60%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25% 1;
    display: flex;
    flex-direction: column;
    max-width: 60px;
    padding: 0 60px;
    justify-content: center;
    align-items: center;
    
  }
  
  #image{
    max-height:  50px;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    #container{
        width: 90%;
    }
    .column {
      flex: 40% 1;
      max-width: 20%;
    }
    #text-about{
        width: 88%;
    }
 
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  

  @media screen and (max-width: 450px) {
    
    #container{
        width: 90%;
    }
    .column {
        padding: 0 30px;
        max-width: 20%;
     
    }
  
    #text-about{
        width: 90%;
    }
 
  }
.dialog-background-color {
  background: rgb(24, 23, 23);
  color: #ffffff;
}

.img-thumbnail {
  width: 200px;
  height: 250px;
}

#project-img {
  height: 100px;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2;
  right: 3;
  width: 87%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgb(24, 23, 23);
}
.container {
  position: relative;
}
.container:hover .overlay {
  opacity: 0.8;
}

@media screen and (max-width: 450px) {
  .dialog-background-color {
    width: 100vw;
  }
  #iframe {
    width: 90vw;
  }
}

#footer {
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 30vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#contact-details {
  font-size: 1.2rem;
}

hr {
  width: 90%;
  background: #ffffff;
}
.company-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.contact-list {
  list-style: none;
 
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
  margin: 0 auto;
}


.list-items {
  display: flex;
  flex-wrap: nowrap;

}
.fa {
  padding-right: 6px;
  font-size: 1.2rem;
}
.fab {
  padding-right: 6px;
  font-size: 1.5rem;
  padding-bottom: 0;
}

@media screen and (max-width: 800px) {
    .list-items {
        
        margin: 3px 10%;
      }
      .contact-list{
        align-items: flex-start;
      }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 415px) {
    #footer{
        height: 36vh;
    }
    .company-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    
    
      }
      .contact-list{
          align-items: flex-start;
         justify-content: center;
         width: 90%;
      }
}

