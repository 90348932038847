.dialog-background-color {
  background: rgb(24, 23, 23);
  color: #ffffff;
}

.img-thumbnail {
  width: 200px;
  height: 250px;
}

#project-img {
  height: 100px;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2;
  right: 3;
  width: 87%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgb(24, 23, 23);
}
.container {
  position: relative;
}
.container:hover .overlay {
  opacity: 0.8;
}

@media screen and (max-width: 450px) {
  .dialog-background-color {
    width: 100vw;
  }
  #iframe {
    width: 90vw;
  }
}
