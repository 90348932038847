#about{
    height: 100vh;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#text-about{
    width: 60%;
    text-align: left;
}
#container{
    width: 60%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    display: flex;
    flex-direction: column;
    max-width: 60px;
    padding: 0 60px;
    justify-content: center;
    align-items: center;
    
  }
  
  #image{
    max-height:  50px;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    #container{
        width: 90%;
    }
    .column {
      flex: 40%;
      max-width: 20%;
    }
    #text-about{
        width: 88%;
    }
 
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  

  @media screen and (max-width: 450px) {
    
    #container{
        width: 90%;
    }
    .column {
        padding: 0 30px;
        max-width: 20%;
     
    }
  
    #text-about{
        width: 90%;
    }
 
  }